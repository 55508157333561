<template>
  <div class="detail-overview__none" v-if="props.noReport">
    <img src="@/assets/img/no-report.png" alt="">
    <div>{{$t('report.overview.chart.non_task')}}</div>
  </div>
  <template v-else>
    <div v-if="props.process === 100" ref="chart" class="detail-overview__chart" @mouseenter="showArrow = true" @mouseleave="showArrow = show = false">
      <div class="scoreList"  v-if="showArrow">
        <el-icon class="el-icon--right" v-if="props.isShowArrow" @click="show = !show"><ArrowDown :class="{'active' : show}"/></el-icon>
        <el-collapse-transition>
          <div class="scores" v-show="show">
            <div v-if="isShowdynamic">{{$t('report.overview.chart.dynamic_score')}}：{{ props.dynamic_score !== 0 ? props.dynamic_score.toFixed(1) : props.dynamic_score }}</div>
            <div v-if="isShowScore">{{$t('report.overview.chart.static_score')}}：{{props.score !== 0 ? (props.score/10).toFixed(1) : props.score }}</div>
          </div>
        </el-collapse-transition>
      </div>
    </div>
    <div class="detail-overview__running" v-else>
      <img :src="$img('detection/running.gif')" alt="">
      <div>{{formatter(props.status)}}</div>
    </div>
  </template>
</template>

<script setup>
import * as echarts from 'echarts'
import { ArrowDown } from '@element-plus/icons-vue'
import { defineProps, ref, onMounted, computed, watch, nextTick } from 'vue'
import { isDark, watchSwitchTheme } from 'app/style'
import { t } from 'app/i18n'

const chart = ref(null)

const show = ref(false)
const showArrow = ref(false)

const props = defineProps({
  y: {
    default: 55
  },
  combined: {
    default: 0
  },
  score: {
    default: 0
  },
  dynamic_score: {
    default: 0
  },
  top: {
    default: 155
  },
  process: {
    default: 100,
    type: Number
  },
  status: {
    default: '',
    type: String
  },
  isShowArrow: {
    default: false,
    type: Boolean
  },
  onlyIncludeStatic: {
    default: false,
    type: Boolean
  },
  noReport: {
    default: false,
    type: Boolean
  }
})

const isShowdynamic = computed(() => typeof props.dynamic_score === 'number' && !props.onlyIncludeStatic)

const isShowScore = computed(() => typeof props.score === 'number')

const scoreType = computed(() => props.combined > 7.4 ? { label: t('report.overview.chart.is_threaten.malice'), value: '#ff5967' } : (props.combined > 4.9 ? { label: t('report.overview.chart.is_threaten.suspicious'), value: '#e69f32' } : { label: t('report.overview.chart.is_threaten.secure'), value: '#00ab7a' }))

watch([() => props.process, () => props.combined], (value) => {
  if (props.process === 100) {
    nextTick(() => draw())
  }
})

watchSwitchTheme(() => {
  draw()
})

function formatter (val) {
  switch (val) {
    case 'waiting':
    case 'pending':
      return t('report.status.pending')
    case 'scanning':
      return t('report.status.scanning')
    case 'scanning_completed':
      return t('report.status.scanning_completed')
    case 'policy_running':
      return t('report.status.policy_running')
    case 'dynamic_pending':
      return t('report.status.dynamic_pending')
    case 'static_dispatch_waiting':
      return t('report.status.static_dispatch_waiting')
    case 'dynamic_dispatch_waiting':
      return t('report.status.dynamic_dispatch_waiting')
    case 'running':
      return t('report.status.running')
    case 'processing':
      return t('report.status.processing')
    case 'completed':
      return t('report.status.completed')
    case 'reported':
      return t('report.status.reported')
    default:
      return t('report.status.default')
  }
}

onMounted(() => {
  draw()
})

function draw() {
  if (!chart.value) return
  const instance = echarts.init(chart.value, null, { renderer: 'svg' })

  const gaugeData = [
    {
      value: props.combined * 10,
      name: scoreType.value.label,
      title: {
        offsetCenter: ['0%', `${props.top}%`],
        color: scoreType.value.value,
        fontSize: 14,
        fontFamily: 'PdfPingFang'
      },
      itemStyle: {
        color: scoreType.value.value
      }
    }
  ]
  const option = {
    tooltip: {
      formatter: '{a} <br/>{b} : {c}%'
    },
    // 标题
    title: [
      {
        text: gaugeData[0].value !== 0 ? (gaugeData[0].value / 10).toFixed(1) : gaugeData[0].value / 10,
        x: 'center',
        y: props.y,
        textStyle: {
          fontSize: '25',
          color: scoreType.value.value,
          fontFamily: 'DIN Alternate',
          foontWeight: '600'
        }
      }
    ],

    series: [
      // 内圆
      {
        type: 'pie',
        radius: '55%',
        center: ['50%', '40%'],
        animation: false,
        z: 3,
        itemStyle: {
          shadowColor: 'red',
          shadowBlur: 12,
          normal: {
            color: isDark() ? '#3c4157' : '#fff',
            label: {
              show: false
            },
            labelLine: {
              show: false
            },
            borderColor: isDark() ? '#535971' : '#fff',
            shadowBlur: 10,
            shadowColor: '#0000001f',
            borderWidth: 2
          }
        },
        hoverAnimation: false,
        label: {
          show: false
        },
        tooltip: {
          show: false
        },
        data: [100]
      },
      {
        type: 'gauge',
        center: ['50%', '40%'],
        radius: '78%',
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            borderWidth: 0
          }
        },
        axisLine: {
          show: false,
          lineStyle: {
            width: 6
          }
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false,
          distance: 50
        },
        data: gaugeData,
        title: {
          fontSize: 14,
          offsetCenter: ['0', '100%']
        }
      },
      {
        radius: '66%',
        center: ['50%', '40%'],
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false
        },
        animation: false,
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            borderWidth: 0,
            color: isDark() ? '#2b3046' : '#F8FBFE'
          }
        },
        axisLine: {
          show: false,
          lineStyle: {
            width: 9
          }
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false,
          distance: 50
        },
        data: [100]
      },
      // 外圆
      {
        type: 'pie',
        radius: '83%',
        center: ['50%', '40%'],
        animation: false,
        z: 0,
        itemStyle: {
          normal: {
            color: isDark() ? '#202330' : '#E8EAED',
            label: {
              show: false
            },
            labelLine: {
              show: false
            },
            borderColor: isDark() ? '#2f344a' : '#fff',
            shadowBlur: 10,
            shadowColor: '#0000002d',
            borderWidth: 2
          }
        },
        hoverAnimation: false,
        label: {
          show: false
        },
        tooltip: {
          show: false
        },
        data: [100]
      }
    ]
  }
  instance.setOption(option)
}
</script>
<style lang="scss" scoped>
.detail-overview__chart {
  width: 147px;
  height: 180px;
  position: relative;
  .scoreList {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    width: 90px;
    top: 84px;
    left: 28px;
    .el-icon {
      margin: 4px auto;
      color: #00ab7a;
      svg {
        transition: all 0.5s;
      }
    }
    i {
      & .active {
        transform: rotateZ(180deg);
      }
    }
    .scores {
      border-radius: 2px;
      box-shadow: 0 2px 4px 0 var(--popover-box-shadow1);
      div {
        height: 28px;
        width: 100px;
        line-height: 28px;
        background: var(--report-analyse-mask);
        text-align: center;
        font-size: 12px;
        color: var(--color-text-2);
        cursor: pointer;
        &:hover {
          color: #00ab7a;
        }
      }
    }
  }
}
.detail-overview__running, .detail-overview__none {
  img {
    width: 147px;
  }
  div {
    text-align: center;
    color: var(--color-text-2);
    width: 148px;
  }
}
.detail-overview__none {
  img {
    margin-top: -40px;
    margin-bottom: 10px;
  }
}
</style>
