<template>
  <div>
    <div class="search-skill-header" :class="{'release': !showLine}">
      <span v-if="showLine" class="header-wrap__line"></span>
      <span class="header-wrap__title" :class="{bigFont: !showLine}">{{$t('report.analyse.static.title')}}</span>
      <span :class="['header-wrap__type', level.type]">{{ level.name }}</span>
      <span v-if="isSearch" v-copy="{type: props.type === 'dropped' ? 'dropped_threat_level' : 'memdump_threat_level', value: props.zserver.threat_level, isNum: true}"></span>
    </div>
    <table v-if="tableData.length > 0" class="search-skill-table">
      <tr>
        <th>{{$t('report.analyse.static.engine_judgment.key')}}</th>
        <th>{{$t('report.analyse.static.engine_judgment.result')}}</th>
      </tr>
      <tr v-for="(item, index) in tableData" :key="`${item.virus_name.value}${index}`">
        <td>
          <svg-icon class="yinqin" name="yinqin"></svg-icon>
          <span>{{ item.engine_name }}</span>
        </td>
        <td :class="scanType(item.scan_status)">
          <svg-icon :name="scanType(item.scan_status)"></svg-icon>
          <span>{{ item.virus_name.value }}</span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { t, isZh } from 'app/i18n'
import { useRoute } from 'vue-router'
const route = useRoute()
const props = defineProps({
  zserver: {
    type: Object
  },
  showLine: {
    type: Boolean,
    default: true
  },
  type: {
    type: String,
    default: 'dropped'
  }
})

const engineKeyMap = {
  qvm_info: t('report.analyse.static.engine_judgment.engine_key_map.qvm_info'),
  aqvm_info: t('report.analyse.static.engine_judgment.engine_key_map.aqvm_info'),
  bd_info: t('report.analyse.static.engine_judgment.engine_key_map.bd_info'),
  sign_info: t('report.analyse.static.engine_judgment.engine_key_map.sign_info'),
  avira_info: t('report.analyse.static.engine_judgment.engine_key_map.avira_info'),
  ave_info: t('report.analyse.static.engine_judgment.engine_key_map.ave_info'),
  kpav_info: t('report.analyse.static.engine_judgment.engine_key_map.kpav_info'),
  avm_info: t('report.analyse.static.engine_judgment.engine_key_map.avm_info'),
  qex_info: t('report.analyse.static.engine_judgment.engine_key_map.qex_info'),
  cloud_info: t('report.analyse.static.engine_judgment.engine_key_map.cloud_info')
}

for (const k in engineKeyMap) {
  engineKeyMap[k] = t(`report.analyse.static.engine_judgment.engine_key_map.${k}`)
}

const isSearch = computed(() =>
  route.path.includes('datasub/search')
)

const level = computed(() => {
  if (!props.zserver) {
    return { name: t('report.analyse.static.engine_judgment.risk.not_detected'), type: '' }
  }
  switch (props.zserver.threat_level) {
    case 70:
      return { name: t('report.analyse.static.engine_judgment.risk.malice'), type: 'red', color: '#ff5967' }
    case 50:
      return { name: t('report.analyse.static.engine_judgment.risk.suspicious'), type: 'orange', color: 'orange' }
    case 40:
      return { name: t('report.analyse.static.engine_judgment.risk.not_detected'), type: '' }
    case 10:
      return { name: t('report.analyse.static.engine_judgment.risk.non_risk'), type: 'green', color: '#00ab7a' }
    default:
      return { name: t('report.analyse.static.engine_judgment.risk.not_detected'), type: '' }
  }
})
const detail_info = computed(() => {
  if (!props.zserver || !props.zserver.detail_info) {
    return []
  }
  return props.zserver.detail_info
})
const tableData = computed(() => {
  const virusList = []
  if (Object.keys(detail_info.value).length === 0) {
    return []
  }
  if (props.zserver.threat_level === 50 || props.zserver.threat_level === 70) {
    for (const [key, val] of Object.entries(detail_info.value)) {
      if (val.scan_status === 1 || val.scan_status === 3) {
        virusList.push({
          engine_name: engineKeyMap[key],
          scan_status: val.scan_status,
          virus_name: {
            color: val.scan_status === 1 ? '#F34E4E' : 'orange',
            value: val.virus_name
          }
        })
      }
    }
  } else if (props.zserver.threat_level === 10 || props.zserver.threat_level === 20) {
    for (const key of ['cloud_info', 'sign_info']) {
      if (detail_info.value[key].scan_status === 2) {
        virusList.push({
          engine_name: engineKeyMap[key],
          scan_status: detail_info.value[key].scan_status,
          virus_name: {
            color: '#00ab7a',
            value: isZh() ? detail_info.value[key].cn : detail_info.value[key].cn === '安全文件' ? 'Safe file' : detail_info.value[key].cn
          }
        })
      }
    }
  }
  return virusList.filter((item) => item.engine_name)
})

const scanType = (status) => {
  switch (status) {
    case 1:
      return 'high'
    case 2:
      return 'low'
    case 3:
      return 'middle'
    default:
      return 'low'
  }
}
// const svgPath = (type) => {
//   return require(`@/assets/img/svg/${type}.svg`)
// }
</script>

<style lang="scss" scoped>
.release {
  padding-bottom: 10px;
  box-shadow: 0 1px 0 0 var(--color-border-2);
}
.search-skill-header  {
  display: flex;
  align-items: center;
  .header-wrap__line  {
    display: inline-block;
    height: 12px;
    width: 3px;
    background-color: #00ab7a;
    margin-right: 6px;
  }
  .header-wrap__title  {
    color: var(--color-text-2);
    font-size: 14px;
    margin-right: 16px;
    font-weight: 500;
    &.bigFont {
      font-size: 16px;
      color: var(--color-text-1);
    }
  }
  .header-wrap__type  {
    display: inline-block;
    color: #fff;
    border-radius: 2px;
    padding: 0px 6px;
    font-size: 14px;
    line-height: 20px;
    background: #7E7F86;
  }
  .header-wrap__type.red  {
    background: #ff5967;
  }
  .header-wrap__type.green  {
    background: #00ab7a;
  }
  .header-wrap__type.orange  {
    background: #e69f32;
  }
  :deep(.sand-gradeCollapseGroup)  {
    margin-top: 20px;
  }
}
.search-skill-header+.search-skill-header  {
  margin-top: 20px;
}
.search-skill-table  {
  margin: 16px 0 18px;
  border-collapse: collapse;
  width: 100%;
  tr:first-child {
    th {
      // color: var(--color-text-2);
      color: var(--color-text-1);
      background: var(--color-bg-3);
    }
  }
  tr  {
    th, td  {
      height: 40px;
      box-sizing: border-box;
      padding: 5px 20px;
      font-size: 14px;
      border-bottom: 1px solid var(--color-border-2);
      // @include ft_color(color-main-font);
      // @include bd_color(color-sand-border);
      text-align: left;
      svg {
         margin-right: 10px;
      }
      .yinqin {
        color: #00ab7a;
      }
      i  {
        margin-right: 10px;
        vertical-align: middle;
      }
      &:first-child  {
        width: 380px;
      }
      &:not(:last-child)  {
        border-right: 0;
      }
    }
    th  {
      // @include bg_color(color-sand-title-bg);
      font-weight: 500;
    }
    td  {
      font-weight: 400;
    }
    td.high  {
      color: #ff5967;
    }
    td.middle  {
      color: #BEA040;
      // @include ft_color(color-collapse-middle);
    }
    td.low  {
      color: #3EAE89;
      // @include ft_color(color-collapse-low);
    }
  }
}

[data-theme='light'] {
  .search-skill-table  {
    tr {
      td.high  {
        color: #cf1322;
      }
      td.middle  {
        color: #e69f32;
        // @include ft_color(color-collapse-middle);
      }
      td.low  {
        color: #00ab7a;
        // @include ft_color(color-collapse-low);
      }
    }
  }
}
</style>
