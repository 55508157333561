<template>
  <div class="sand-gradeCollapseGroup" v-if="data.childrenData || data.lockData > 0">
      <div class="sand-gradeCollapseGroup__title" v-if="data.title !== undefined">
        <div v-if="!data.hideSquare" class="sand-gradeCollapseGroup__title--square"></div>
        {{data.title}}
      </div>
      <div class="sand-gradeCollapseGroup__box">
        <el-collapse v-model="collapseVal">
          <el-collapse-item v-for="(item, i) in data.childrenData" :key="i" :name="i">
            <template #title>
              <div :class="`sand-gradeCollapseGroup__header sand-gradeCollapseGroup__header--${item.type}`">{{item.title}}</div>
            </template>
            <slot :childrenData="item.childrenData"></slot>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div v-if="data.lockData > 0" class="sand-gradeCollapseGroup__lock">
        <template v-if="data.lockData > 0">
          <div>{{$t('upgrade.lockdata', [data.lockData])}}</div>
          <div class="operation">
            <span class="auth-wrap-link" @click="handleClick('upgrade')">{{$t('message.upgrade')}}</span>
            <span class="auth-wrap-link" @click="handleClick('integral')">{{$t('message.integral')}}</span>
            <span class="auth-wrap-link" @click="handleClick('detail')">{{$t('message.detail')}}</span>
          </div>
        </template>
      </div>
  </div>
  <sand-no-data v-else></sand-no-data>
</template>
<script>
import emitter from '@/app/emitter.js'
import sandNoData from './sandNoData'
import { mapGetters } from 'vuex'
export default {
  components: {
    sandNoData
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        // title: '微软软件',
        // hideSquare: true,
        // childrenData: [
        //   {
        //     title: 'Powershell 进程与一个隐藏窗口被执行（1事件）',
        //     type: 'high',
        //     childrenData: {}
        //   }, {
        //     title: '33333',
        //     type: 'middle',
        //     childrenData: {}
        //   }, {
        //     title: '33333',
        //     type: 'low',
        //     childrenData: {}
        //   }
        // ]
      })
    }
  },
  data () {
    return {
      collapseVal: []
    }
  },
  computed: {
    ...mapGetters(['gid']),
    reportState () {
      return this.$store.getters.getReportState
    }
  },
  watch: {
    reportState () {
      this.setReportStatic()
    }
  },
  mounted () {
    this.setReportStatic()
  },
  methods: {
    setReportStatic () {
      if (this.reportState) {
        this.open()
      }
    },
    open (index) {
      // if (index !== undefined) {
      //   this.collapseVal[index] = []
      //   this.collapseData[index].childrenData.forEach((_, i) => {
      //     this.collapseVal[index].push(i)
      //   })
      // } else {
      //   this.collapseData.forEach((item, i) => {
      //     this.open(i)
      //   })
      //   this.isOpen = true
      // }
    },
    close (index) {
      // this.collapseVal = this.collapseVal.map(_ => [])
    },
    handleClick (type) {
      switch (type) {
        case 'login':
          emitter.emit('showLogin')
          break
        case 'upgrade':
          this.$router.push({
            name: 'subscribe',
            query: {
              type: 'purchase',
              group: 'professional',
              meal_type: 'professional',
              price_type: 'one_month'
            }
          })
          break
        case 'integral':
          if (this.gid === 0) {
            emitter.emit('showLogin')
          } else {
            this.$store.commit('SHOW_EXCHANGE_DIALOG', 'B')
          }
          break
        case 'detail':
          // eslint-disable-next-line no-case-declarations
          const { href } = this.$router.resolve({ name: 'price' })
          window.open(href, '_blank')
          break
        default:
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.sand-gradeCollapseGroup  {
  width: 100%;
  margin-bottom: 10px;
  &__title  {
    // @include ft_color(color-main-font);
    height: 48px;
    line-height: 48px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 30px;
    color: var(--color-text-2);
    &--square  {
      width: 3px;
      height: 12px;
      background: #00ab7a;
      margin: 18px 6px 12px 0;
      float: left;
    }
  }
  &__box  {
    & > .el-collapse  {
      border: 0;
    }
  }
  &__lock  {
    border-top: 1px solid var(--color-border-2);
    background: var(--color-bg-3);
    color: var(--color-text-2);
    height: 48px;
    line-height: 48px;
    text-align: center;
    padding-left: 20px;
    font-size: 14px;
    display: flex;
    justify-content: start;
    position: relative;
    .auth-wrap-link  {
      color: var(--color-priamry-9);
      color: #00ab7a;
      cursor: pointer;
    }
    .operation  {
      position: absolute;
      right: 20px;
      .auth-wrap-link  {
        margin-left: 10px;
      }
    }
  }
  &__header  {
    line-height: 48px;
    color: var(--color-text-2);
    max-width: calc(100% - 40px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    &::before {
      content: '';
      display: inline-block;
      width:2px;
      height: 49px;
      position: absolute;
      left: 0;
      right: 0;
    }
    &--high  {
      color: #ff5967;
      &::before {
        background: #ff5967;
      }
    }
    &--middle  {
      color: #e69f32;
      &::before {
        background: #e69f32;
      }
    }
    &--low  {
      color: #00ab7a;
      &::before {
        background: #00ab7a;
      }
    }
  }
  & :deep(.el-collapse-item__header)  {
    margin: 0 !important;
    border-bottom: 1px solid var(--color-border-2);
    background-color: var(--color-bg-3) !important;
    position: relative;
    // border: 0;
    padding-left: 20px;
    font-weight: 400;
    height: 48px;
    font-size: 14px;
    // @include bg_color(color-collapse-header-bg);
    margin-top: 6px;
    &:hover  {
      // @include bg_color(color-collapse-bg-hover);
      & .el-icon-arrow-right:before  {
        filter: brightness(--svg-filter2);
      }
    }
  }

  /* 把默认边框去掉 */
  & :deep(.el-collapse),
  & :deep(.el-collapse-item__wrap)  {
    border-width: 0;
    background: transparent;
  }
  & :deep(.el-collapse-item)  {
    &:first-child  {
      .el-collapse-item__header  {
        margin-top: 0px;
      }
    }
  }
  /* 右边的小三角形替换一下 */
  & :deep(.el-collapse-item__arrow.is-active)  {
    // transform: rotate(180deg);
  }
  & :deep(.el-collapse-item__arrow)  {
    height: 13px;
    margin-right: 7px;
  }
  & :deep(.el-icon-arrow-right):before  {
    content: url('./../../assets/img/svg/triangle.svg');
  }

  /* 内容上下边距改一下 */
  & :deep(.el-collapse-item__content)  {
    padding: 16px 0 !important;
    // @include bd_color(color-collapse-content-bg);
    // border: 1px solid;
    // @include bd_color(color-sand-border);
    border-top-width: 0;
  }
}

[data-theme='light'] {
  .sand-gradeCollapseGroup {
    &__header  {
      &--high  {
        color: #cf1322;
        &::before {
          background: #cf1322;
        }
      }
      &--middle  {
        color: #e69f32;
        &::before {
          background: #e69f32;
        }
      }
      &--low  {
        color: #00ab7a;
        &::before {
          background: #00ab7a;
        }
      }
    }
  }
}
</style>
